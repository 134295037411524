// Generated by Framer (f1edf09)

import { addFonts, cx, CycleVariantState, SVG, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["AQPHj1vkX"];

const variantClassNames = {AQPHj1vkX: "framer-v-cr9tgk"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "AQPHj1vkX", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "AQPHj1vkX", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CjPS9", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-cr9tgk", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AQPHj1vkX"} ref={ref} style={{...style}} transition={transition}><SVG className={"framer-111iqsn"} data-framer-name={"Group"} layout={"position"} layoutDependency={layoutDependency} layoutId={"PQxIu07Lw"} opacity={1} radius={0} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 15 15\"><path d=\"\" fill=\"rgba(0,170,255,0.5)\" stroke=\"#0AF\"></path><path d=\"M 3.5 11.5 L 11.5 3.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"#495976\" stroke-linecap=\"round\" stroke-miterlimit=\"5.769230769230768\" stroke-dasharray=\"\"></path><path d=\"M 11.198 11.198 L 3.5 3.5\" fill=\"transparent\" stroke-width=\"2\" stroke=\"#495976\" stroke-linecap=\"round\" stroke-miterlimit=\"5.769230769230768\" stroke-dasharray=\"\"></path></svg>"} svgContentId={2694498235} transition={transition} withExternalLayout/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CjPS9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CjPS9 * { box-sizing: border-box; }", ".framer-CjPS9 .framer-b2a8eg { display: block; }", ".framer-CjPS9 .framer-cr9tgk { height: 15px; overflow: hidden; position: relative; width: 15px; }", ".framer-CjPS9 .framer-111iqsn { flex: none; height: 15px; left: calc(46.66666666666669% - 15px / 2); position: absolute; top: calc(50.00000000000002% - 15px / 2); width: 15px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15
 * @framerIntrinsicWidth 15
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 */
const FramerCwBKJpTGw: React.ComponentType<Props> = withCSS(Component, css, "framer-CjPS9") as typeof Component;
export default FramerCwBKJpTGw;

FramerCwBKJpTGw.displayName = "X";

FramerCwBKJpTGw.defaultProps = {height: 15, width: 15};

addFonts(FramerCwBKJpTGw, [])